body {
  height: calc(100vh - 64px);
  height: calc(var(--vh, 1vh) * 100 - 64px);
  min-height: calc(100vh - 64px);
  min-height: calc(var(--vh, 1vh) * 100 - 64px);
  max-height: calc(100vh - 64px);
  max-height: calc(var(--vh, 1vh) * 100 - 64px);
  background-color: #1b5e20;
  overflow-y: hidden;
  padding-top: 64px;
}

.fuller-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
}

.full-height {
  height: calc(100vh - 64px);
  height: calc(var(--vh, 1vh) * 100 - 64px);
  min-height: calc(100vh - 64px);
  min-height: calc(var(--vh, 1vh) * 100 - 64px);
  max-height: calc(100vh - 64px);
  max-height: calc(var(--vh, 1vh) * 100 - 64px);
  overflow-y: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
  font-family: acumin-pro, sans-serif !important;
}

.logo {
  fill: #ffeb3b;
  margin: -6px 6px 0 0;
}

.short {
  overflow-y: auto;
}

.yellow {
  background-image: linear-gradient(
    to bottom,
    #1b5e20 0%,
    #4caf50 100%
  ) !important;
}

.white {
  background-image: linear-gradient(
    to bottom,
    #ffffff 0%,
    #ffffff 100%
  ) !important;
}

.yellowNav {
  background: #1b5e20 !important;
}

.whiteNav {
  background: #ffffff !important;
}

.alert-pre {
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}

.small-caps {
  font-variant: all-small-caps;
}

.border-left-5 {
  border-left: 5px solid;
}

.loadingModal .modal-content {
  background-color: transparent !important;
  border: none;
}
